import { ISeparator, IWeatherItem } from '../../types'
import {
  Container,
  IconsTitle,
  IconWrapper,
  SocialContainer,
  SocialIcons,
  WeatherContainer,
  WeatherIconDiv,
  WeatherText,
} from './Separator.styles'

type Props = {
  separator: ISeparator
}

const WeatherEl = (weatherData: IWeatherItem) => {
  const { city, temperature, description, icon_class } = weatherData
  return (
    <WeatherContainer>
      <WeatherText>{`${city} ${temperature}°, ${description}`}</WeatherText>
      <WeatherIconDiv theme={{ icon_class: icon_class }} />
      {/*<WeatherIcon src={icon_img}/>*/}
    </WeatherContainer>
  )
}

export const Separator = ({ separator }: Props) => {
  const { icons_title, social, weather } = separator
  // Temporary solution; weather object might change in the future
  try {
    if (!weather) {
      return null
    }
    const weatherData = weather['Tel Aviv']
    return (
      <Container>
        <SocialContainer>
          <IconsTitle>{icons_title}</IconsTitle>
          <SocialIcons>
            {social.slice(0, 8).map(({ icon, link }, index) => {
              return (
                <IconWrapper href={link} key={index} target='_blank'>
                  <img
                    loading='lazy'
                    src={icon}
                    alt={`${icons_title} ${index + 1}`}
                  />
                </IconWrapper>
              )
            })}
          </SocialIcons>
        </SocialContainer>
        {weatherData && <WeatherEl {...weatherData} />}
      </Container>
    )
  } catch (e) {
    console.log(
      '%cSeparator ',
      'background-color:darkred;color:white;padding:2px 5px;',
      weather
    )
  }
  return null
}
export default Separator
