import styled from 'styled-components'
import { colors, device } from '../../styles/GlobalVariables'
import Link from '../Link/Link'

export const Container = styled.div`
  height: 47px;
  display: none;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 28px;

  border-top: 1px solid #c4c4c4b2;
  border-bottom: 1px solid #c4c4c4b2;

  ${device.medium} {
    display: flex;
  }
`

export const SocialContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 24px;
`

export const IconsTitle = styled.span`
  font-size: 16px;
  font-weight: 700;
  color: ${colors.black};
`

export const SocialIcons = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`

export const IconWrapper = styled(Link)`
  display: flex;
  align-items: center;

  max-height: 25px;
  max-width: 25px;
`

export const WeatherContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 13px;
`

export const WeatherText = styled.span`
  font-size: 16px;
  font-weight: 700;
  color: ${colors.black};
`

export const WeatherIcon = styled.img`
  height: 25px;
  width: auto;
`
export const WeatherIconDiv = styled.div`
  &:before {
    font-family: reshet13 !important;
    speak: never;
    font-style: normal;
    font-weight: normal;
    font-variant: normal;
    text-transform: none;
    line-height: 1;
    font-size: 30px;

    ${props => {
      switch (props.theme.icon_class) {
        case 'weather-code-1140':
          return 'content: "\\e900";'
        case 'weather-code-1270':
          return 'content: "\\e901";'
        case 'weather-code-1310':
          return 'content: "\\e902";'
        case 'weather-code-1580':
          return 'content: "\\e903";'
        case 'weather-code-1230':
          return 'content: "\\e904";'
        case 'weather-code-1220':
          return 'content: "\\e905";'
        case 'weather-code-1530':
          return 'content: "\\e906";'
        case 'weather-code-1220n':
          return 'content: "\\e907";'
        case 'weather-code-1530n':
          return 'content: "\\e908";'
        case 'weather-code-1540':
          return 'content: "\\e909";'
        case 'weather-code-1510':
          return 'content: "\\e90a";'
        case 'weather-code-1010':
          return 'content: "\\e90b";'
        case 'weather-code-1010n':
          return 'content: "\\e90c";'
        case 'weather-code-1020':
          return 'content: "\\e90d";'
        case 'weather-code-1160':
          return 'content: "\\e90e";'
        case 'weather-code-1320':
          return 'content: "\\e90f";'
        case 'weather-code-1590':
          return 'content: "\\e910";'
        case 'weather-code-1300':
          return 'content: "\\e911";'
        case 'weather-code-1260':
          return 'content: "\\e912";'
        case 'weather-code-10701':
          return 'content: "\\e913";'
        case 'weather-code-1060':
          return 'content: "\\e914";'
        case 'weather-code-1080':
          return 'content: "\\e915";'
        case 'weather-code-1070':
          return 'content: "\\e916";'
        case 'weather-code-1570':
          return 'content: "\\e917";'
        case 'weather-code-1570n':
          return 'content: "\\e918";'
        case 'weather-code-1250':
          return 'content: "\\e919";'
        case 'weather-code-1250n':
          return 'content: "\\e91a";'
      }
    }}
  }
`
